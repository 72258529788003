
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        













































































































































































































.countdown,
[class*='countdown--'] {
  position: relative;
  width: 100%;
  max-width: 39rem;
  background-color: $white;
  border-radius: 5px;

  .overlayer & {
    margin: 0 auto;
  }

  &[class*='--outline'] {
    background-color: transparent;
    backdrop-filter: blur(10px);
  }

  &[class*='--transparent'] {
    background-color: transparent;
  }

  &[data-status='finished'] {
    display: none;
  }

  &[class*='--no-style'] {
    display: inline;
    width: fit-content;
    background: none;
    border: none;
  }
}

.countdown__label {
  @extend %fw-bold;
  @extend %text-uppercase;
  @include center-x;

  position: absolute;
  z-index: 1;
  top: 0;
  display: inline-block;
  width: auto;
  padding: 0.6rem 0.8rem 0.5rem;
  color: $white;
  font-size: 1.1rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  background-color: $c-gray-darkest;
  border-radius: 0.3rem;
  transform: translate(-50%, -50%);

  [class*='countdown--'][class*='--orange'] & {
    background-color: $c-orange;
  }

  [class*='countdown--'][class*='--pink'] & {
    background-color: $c-pink-medium;
  }

  [class*='countdown--'][class*='--cyan'] & {
    background-color: $c-cyan-contrast;
  }

  [class*='countdown--'][class*='--white'] & {
    color: $c-blue-dark;
    background-color: $c-light;
  }
}

// No-style version (text)
.countdown__text__item {
  @extend %fw-semi;

  &:nth-last-child(n + 3)::after {
    content: ', ';
    position: static;
    margin-right: 0.2em;
  }

  &:last-child {
    &::before {
      content: ' ' attr(data-and);
    }
  }
}

// Regular version
.countdown__number {
  @extend %text-center;
  @extend %fw-bold;

  margin: 0;
  padding: 1rem 0 0.5rem;
  color: $c-pink-medium;
  font-family: $ff-alt;
  font-size: 4rem;
  line-height: 1;

  .overlayer & {
    display: none;
  }

  small {
    display: block;
    font-size: 1.4rem;
    line-height: 1;
  }

  @include mq(l) {
    font-size: 8rem;

    small {
      font-size: 2.4rem;
    }
  }

  @include mq(xl) {
    font-size: 14rem;

    small {
      font-size: 4.1rem;
    }
  }
}

.countdown__table-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 2.4rem 1.5rem 1.1rem;
  color: $c-blue-dark;
  background-color: transparent;
  border: 1px solid $c-blue-dark;
  border-radius: 1rem;

  [class*='countdown--'][class*='--pink'] & {
    border: 1px solid $c-pink-medium;
  }

  [class*='countdown--'][class*='--cyan'] & {
    border: 1px solid $c-cyan-contrast;
  }

  [class*='countdown--'][class*='--outline'] & {
    color: $c-light;
    border: 1px solid $c-light;
  }

  @include mq(l) {
    padding: 3.4rem 1.5rem 2.1rem;
  }
}

.countdown__table {
  width: 100%;
}

.countdown__table__row {
  display: flex;
  justify-content: center;
  font-family: $ff-alt;
}

.countdown__table__row__column {
  @extend %fw-ultra;

  position: relative;
  width: 25%;
  font-size: 3rem;
  line-height: 1;
  text-align: center;

  &:not(:last-child) {
    &::before {
      content: ':';
      position: absolute;
      top: -2px;
      right: -6px;
    }
  }

  @include mq(l) {
    font-size: 3.6rem;
  }
}

.countdown__table__row__column__label {
  display: block;
  font-size: 1.1rem;
  text-transform: capitalize;

  @include mq(l) {
    font-size: 1.3rem;
  }
}


          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.countdown--business {
  margin-bottom: 4rem;
}

/* stylelint-disable selector-max-combinators, selector-max-compound-selectors, max-nesting-depth, declaration-no-important, no-descending-specificity */
.app-has-submenu .hero {
  margin-top: $header-height;

  @include mq($until: l) {
    margin-top: 9rem;
  }
}

.hero,
[class*='hero--'] {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: $header-height;

  .btn--no-icon {
    margin-top: 3rem;
  }

  [data-template='net'] &,
  [data-template*='net-'] & {
    padding-top: 0;
  }

  .hero__price-box {
    position: relative;
    z-index: 99;
    overflow: visible;
    width: 100%;
    max-width: 45rem;
    margin-top: $spacing * 1.5;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid $c-blue-lighter;
    border-radius: 1rem;

    // transform: translateY($spacing * 3);

    &.price-box,
    &[class*='price-box--'] {
      .price-box__misc {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .btn {
        margin-top: 0;
      }

      .price-box__prev {
        color: $white;

        &::after {
          background-color: $white;
        }
      }
    }
  }

  .price-box__promo {
    position: absolute;
    top: 0;
    display: inline-block;
    width: auto;
    border-radius: 0.2rem;
    transform: translateY(-50%);
  }

  @include mq($until: l) {
    background-color: $c-blue-dark;
  }

  @include mq('l') {
    height: 100vh;
    max-height: 82rem;
    padding-top: 0;

    &.promo-betv {
      max-height: 87rem;
    }

    // UNIFORMISATION HUB EN ATTENTE REFACTO
    .hub-net &,
    .hub-mobile &,
    .hub-fixe &,
    .giga-fiber & {
      max-height: 69rem;
    }
  }
}

.hero__scroll-outer {
  position: absolute;
  z-index: 99;
  right: $spacing * 2.5;
  bottom: $spacing * 4;
  display: none;

  body:not([data-template='homepage']) & {
    display: none;
  }

  @include mq(l) {
    display: block;
  }
}

.hero__scroll {
  @extend %text-uppercase;
  @extend %fw-black;

  display: flex;
  align-items: center;
  padding: $spacing / 2 $spacing;
  font-family: $ff-alt;
  font-size: 1.1rem;
  letter-spacing: 1px;
  background-color: $white;
  cursor: pointer;
  border-radius: 3rem;
  transform: rotate(-90deg);
  transition: background-color 0.2s $ease-out-quad;

  &:hover {
    background-color: darken($white, 5%);
  }
}

.hero__scroll__icon {
  width: 0.5em;
  margin-right: $spacing / 2;
  transform: rotate(180deg);
  animation: scroll 1s ease-out infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0) rotate(180deg);
  }

  50% {
    transform: translateX(-100%) rotate(180deg);
  }

  100% {
    transform: translateX(0) rotate(180deg);
  }
}

.hero__picture__listing-outer {
  @include get-all-space;

  position: relative;
  // height: calc((21 / 36) * 100vw);
  height: calc((18 / 36) * 100vw);

  @include mq($until: l) {
    .promo-betv & {
      width: auto;
      height: 21rem;
      margin: $spacing * 0.5 $spacing * 0.75;
    }
  }

  @include mq(l) {
    position: absolute;
    height: 100%;
  }
}

.hero__slides,
[class*='hero__slides--'] {
  position: relative;
  width: 100%;
  height: 33rem;

  // @include mq($until: l) {
  //   background-color: $c-blue-dark;
  // }

  &[class*='--solo'] {
    @include mq($until: l) {
      height: auto;

      figure {
        position: relative;
      }

      .hero__slides__item__content {
        padding-bottom: 6rem;
      }
    }
  }

  .tv-extra & {
    @include mq($until: l) {
      height: auto;
    }
  }

  @include mq($until: l) {
    .content.is-solo & {
      height: auto;
    }
  }

  @include mq(l) {
    height: 100%;
  }
}

.hero__slider-outer {
  width: 100%;
  height: 100%;
}

.hero__mask,
[class*='hero__mask--'] {
  @include get-all-space;

  z-index: 99999;
  background: $c-blue-darker;
  transform: translateX(-100%);
}

.hero__slides__item,
[class*='hero__slides__item--'] {
  position: absolute;
  z-index: -1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;

  .tv-extra & {
    position: relative;
  }

  &:first-child {
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &:not(:first-child) {
    // [class*='hero__slides__item__type'],
    .hero__slides__item__title,
    .hero__slides__item__excerpt-outer,
    .hero__slides__item__button {
      opacity: 0;
      transform: translateY(20%);
    }

    .hero__slides__item__promo,
    .hero__slides__item__content {
      opacity: 0;
    }
  }

  .price-box__prev--crossed {
    &::after {
      background-color: $white;
    }
  }

  @include mq($until: l) {
    .content.is-solo & {
      position: static;
    }
  }

  @include mq(l) {
    height: 100%;
  }
}

.hero__slides__item__button {
  margin-top: $spacing / 2;

  @include mq(l) {
    margin-top: $spacing * 2;
  }
}

.hero__slides__item__picture-outer,
[class*='hero__slides__item__picture-outer--'] {
  @include get-all-space;
  @include bg-color;

  z-index: -2;
  height: calc((18 / 36) * 100vw);
  background-color: $c-blue-medium;

  // height: calc((21 / 36) * 100vw);

  &:first-child {
    z-index: -1;

    @include mq($until: l) {
      z-index: 3;
    }
  }

  &:not(:first-child) {
    .hero__slides__item__promo-inner {
      opacity: 0;
    }
  }

  &.is-light {
    background-color: $white;
  }

  .promo-betv & {
    height: 100%;
    background: none;
  }

  @include mq(l) {
    overflow: hidden;
    height: 100%;

    .promo-betv & {
      background-color: $c-blue-medium;
    }
  }
}

.hero__slides__item__picture-inner {
  @include get-all-space;

  overflow: hidden;

  @include mq(l) {
    [class*='--background'] & {
      background-color: $c-promo;
      background-position: 60% 65%;
      background-repeat: no-repeat;
      background-size: 1600px 820px; // BG 3200x1640

      picture {
        display: none;
      }
    }
  }
}

.hero__slides__item__promocontainer {
  @include center-xy;

  z-index: 9;
  width: 100%;

  @include mq($until: l) {
    .promo-betv & {
      display: flex;
      height: 100%;
    }
  }

  @include mq(l) {
    right: col(2);
    left: col(9);
    max-width: 40rem;

    .promo-betv & {
      top: 55%;
    }
  }

  @include mq(xxl) {
    max-width: 45rem;
  }
}

.hero__slides__item__promocontainer-inner {
  @extend %box-shadow;
  @extend %text-center;

  position: relative;
  z-index: 2;
  padding: $spacing * 2.5 $spacing * 2;
  color: $c-light;
  font-family: $ff-alt;
  font-weight: 500;
  background-color: $white;
  border-radius: 4rem;

  .promo-betv & {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $spacing * 1.5;
    border-radius: 4rem 4rem 0 0;
    transform: none;
  }

  @include mq($until: l) {
    @include center-xy;

    width: 90%;
    border-radius: 1.2rem;

    .promo-betv & {
      position: static;
      width: 50%;
      padding: $spacing * 0.75 $spacing;
      border-radius: 1.5rem 0 0 1.5rem;
      transform: none;
    }
  }

  @include mq($until: s) {
    width: 100%;

    .promo-betv & {
      width: 60%;
    }
  }
}

.hero__slides__item__promocontainer__label {
  @extend %fw-black;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
}

.hero__slides__item__promocontainer__subtitle {
  margin: 0;
  color: $black;
}

.hero__slides__item__promocontainer__title {
  @extend %text-uppercase;
  @extend %fw-black;

  margin: $spacing / 2 0;

  @include mq(xl) {
    font-size: 5.8rem;
  }
}

.hero__slides__item__promocontainer__excerpt {
  margin: 0;

  @include mq(l) {
    font-size: 3rem;
  }
}

.hero__slides__item__promocontainer__price {
  width: fit-content;
  margin: 0 auto;
  text-align: left;

  .old-price {
    position: relative;
    display: inline-flex;
    color: $c-pink-medium;
    font-size: 1.2rem;

    &::after {
      @include center-xy;

      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: $c-pink-medium;
      border-radius: 10px;
    }

    @include mq(l) {
      font-size: 2.8rem;
    }
  }

  .new-price {
    color: $c-pink-medium;
    font-size: 4rem;
    font-weight: 900;
    line-height: 1.3em;

    strong {
      font-size: 6rem;
      font-weight: 900;

      @include mq(l) {
        font-size: 17rem;
      }
    }

    small {
      font-size: 1rem;

      @include mq(l) {
        font-size: 3rem;
      }
    }

    @include mq(l) {
      font-size: 10rem;
    }
  }

  .promo-betv & {
    line-height: 1;
  }
}

.hero__slides__item__promocontainer__duration {
  @extend %text-center;

  position: relative;
  width: 85%;
  margin: 0 auto;
  color: $c-blue-dark;
  font-size: 1.5rem;
  line-height: 1.2;

  strong {
    color: $c-pink-medium;
    font-weight: 900;
  }

  .promo-betv & {
    width: 100%;
    margin-bottom: $spacing * 0.5;
  }

  @include mq(l) {
    font-size: 2rem;

    &::before,
    &::after {
      @include center-y;

      position: absolute;
      display: inline-block;
      content: '';
      width: 3rem;
      margin: 0;
      border-top: 0.2rem solid $black;
    }

    &::before {
      right: 0;
      margin-right: -1.5rem;
    }

    &::after {
      left: 0;
      margin-left: 0.5rem;
    }

    .promo-betv &::before {
      margin-right: 0.5rem;
    }
  }
}

.hero__slides__item__promocontainer__disclaimer {
  @include mq($until: m) {
    font-size: 1.4rem;
  }

  font-size: 1.6rem;
  color: $c-blue-dark;

  .promo-betv & {
    line-height: 1.2;
  }
}

.hero__slides__item__promocontainer__picture {
  margin: $spacing auto 0 auto;

  img {
    width: auto;
  }

  @include mq($until: l) {
    max-width: 24rem;
    margin: $spacing * 0.5 auto 0 auto;
  }

  @include mq($until: m) {
    // display: none;
    max-width: 32rem;
  }
}

.hero__slides__item__promocontainer__betv {
  position: relative;
  flex: 1;
}

.hero__slides__item__promocontainer__betv__sticker {
  @extend %text-uppercase;
  @extend %fw-bold;

  position: absolute;
  z-index: 3;
  top: $spacing * 0.5;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: $spacing * 0.5;
  color: $white;
  font-family: $ff-alt;
  font-size: 1rem;
  letter-spacing: 0.05em;
  background: $c-anthracite-lighter;
  border-radius: 1.5rem;

  img {
    margin-bottom: $spacing * 0.5;
  }

  @include mq(l) {
    top: $spacing * 1;
    right: $spacing * 1;
    padding: $spacing;
    font-size: 1.4rem;
  }
}

.hero__slides__item__promocontainer__betv__picture {
  position: relative;
  z-index: 2;
  margin: 0;

  @include mq($until: l) {
    @include get-all-space;

    img {
      @include image-fit;
    }
  }
}

.hero__slides__item__promocontainer__speedlines {
  z-index: 1;
  top: -50% !important;
  left: -50% !important;
  width: 200% !important;
  height: 200% !important;

  @include mq($until: l) {
    display: none;
  }
}

.hero__slides__item__picture {
  // See https://git.epic.net/voo/website-v3/track/-/issues/34
  @include image-fit($position: center);

  position: absolute;
  z-index: 1;
  bottom: 0;

  // @include mq(m) {
  //   @include image-fit($position: top);
  // }
}

.hero__slides__item-inner {
  // z-index: 900; // It covers mobile header
  position: relative;
  z-index: 90;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  @include mq('m') {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
  }

  @include mq(l) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.hero__slides__item__content {
  .is-dark & {
    @include mq($until: l) {
      background-color: $c-blue-dark;
    }
  }

  @include mq($until: l) {
    position: relative;
    width: 100vw;
    margin-left: -$spacing;
    padding: $spacing * 1 $spacing $spacing * 6;

    .tv-extra & {
      padding-top: $spacing;
    }

    .content.is-solo & {
      padding: $spacing * 1.5 $spacing;
    }
  }

  @include mq(m, l) {
    margin-right: -$spacing * 3.5;
    margin-left: -$spacing * 3.5;
    padding-right: $spacing * 3.5;
    padding-left: $spacing * 3.5;
  }

  @include mq(l) {
    width: col(8);

    [class*='--promo--small-title'] & {
      width: col(7);
    }
  }

  @include mq(xl) {
    width: col(7);

    .tv-extra & {
      width: col(8);
    }
  }
}

.hero__slides__item__title-outer {
  position: relative;
  z-index: 10;
  margin-top: 0;
  color: $c-blue-dark;

  .is-light & {
    color: $c-blue-dark;

    @include mq($until: l) {
      color: $c-light;
    }
  }

  .is-dark & {
    color: $white;
  }

  strong {
    @extend %fw-bold;
    @extend %text-uppercase;
  }

  .tv-extra & {
    margin-top: $spacing;

    @include mq(l) {
      margin-top: $spacing * 3.5;
    }
  }

  @include mq(l) {
    color: $c-light;
  }
}

.hero__slides__item__type,
[class*='hero__slides__item__type--'] {
  @extend %fw-bold;
  @extend %text-uppercase;

  // margin-bottom: $spacing;
  display: inline-block;
  font-size: 1.6rem;
  letter-spacing: 1px;

  @include mq($until: l) {
    // position: absolute;
    z-index: 999;
    top: calc((24 / 36) * -100vw);
    color: $white;
    font-size: 1.2rem;
  }

  @include mq(m, l) {
    top: calc((18 / 36) * -100vw);
  }
}

.hero__slides__item__excerpt-outer {
  max-width: 60rem;
  margin-top: 3.6rem;
}

.hero__slides__item__excerpt-outer .h5 {
  margin-top: $spacing / 2;
  margin-bottom: $spacing * 1.5;
}

.hero__slides__item__excerpt {
  i {
    font-size: 1.6rem;
    font-style: normal;
  }

  @include mq(m) {
    i {
      font-size: 2rem;
    }
  }
}

.hero__slides__item__baseline {
  @include mq($until: l) {
    font-size: 1.8rem;
  }
}

.hero__slides__item__logo-partner {
  margin-top: $spacing * 1.5;

  img {
    max-height: 7.5rem;
  }
}

.hero__slides__item__link {
  @include get-all-space;

  z-index: 950;
}

.hero__slides__item__link a {
  @include get-all-space;
}

.hero__slides__item__title {
  @extend %fw-bold;

  margin: 0;

  i {
    @extend %fw-normal;

    font-style: normal;
  }

  strong {
    @extend %fw-bold;

    // color: $c-pink-medium;
    text-transform: none;
  }

  @include mq($until: m) {
    font-size: 3rem;
  }
}

.hero__slides__item__suptitle,
.hero__slides__item__subtitle {
  @extend %fw-medium;

  display: block;
  margin: $spacing / 2 0;
}

.hero__slides__item__highlight {
  margin: $spacing 0;
  text-transform: uppercase;
}

.hero__slides__item__promo-outer {
  @include center-xy;

  z-index: 99;
  width: 100%;
  height: 100%;

  @include mq(l) {
    top: 40%;
    left: 70%;
    display: block;
    max-width: 85rem;
    margin-top: 0;
  }
}

.hero__slides__item__promo-inner {
  @include image-fit(contain);

  // top: 20%; // Summer deals - Phase 2
  // height: 70%; // Summer deals - Phase 2
  right: 10%;
  left: initial;
  width: 70%;
  transform: translateY(15%);

  @include mq($until: l) {
    top: 0%;
    right: 10%;
    left: initial;
    width: 80%;
    height: 80%;
  }
}

.hero__slides__item__promo {
  @include image-fit(contain);
}

.hero__slides__speedlines {
  @include get-all-space;

  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;

  img {
    @include center-xy;
  }
}

.hero__slider-nav {
  position: absolute;
  z-index: 5;
  bottom: $spacing;
  left: 2.5rem;
  margin: 0 col(1) 0 0;
  padding: 0;
  line-height: 1;

  @include mq(m) {
    left: col(1);
  }

  @include mq(l) {
    display: none;
  }
}

.hero__slider-nav__item {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  border: 0.2rem solid $c-pink-medium;
  border-radius: 50%;
  list-style-type: none;
  transition: background-color 0.3s $ease 1s;

  button {
    background: transparent;
    border: 0;
  }

  &.is-active {
    background-color: $c-pink-medium;
  }

  &:not(:last-child) {
    margin-right: $spacing * 0.5;
  }

  span {
    display: none;
  }
}

.hero__navigation {
  // position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: col(12);
  margin-top: 2rem;

  // transform: translateY(0%);

  @include mq(l) {
    position: relative;
    top: initial; //Compatibility
    justify-content: flex-start;
    transform: translateY(-120%);
  }
}

.hero__navigation__item {
  // height: 10rem;
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: col(1);
  height: 5rem;
  list-style-type: none;
  transition: 0.3s $ease-out-quad;
  transition-property: height, border, opacity;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;

    &:not(.is-active) {
      opacity: 0.4;
    }
  }

  * {
    pointer-events: none;
  }

  @include mq(l) {
    width: col(2);
    height: 10rem;
  }
}

.hero__navigation__item__title {
  @extend %fw-bold;
  @extend %text-uppercase;

  position: relative;
  z-index: 20;
  overflow: hidden;
  margin-top: $spacing;
  color: $c-gray-light;
  font-size: 0.9rem;
  text-overflow: ellipsis;

  .hero__navigation__item.is-active & {
    color: $c-light;

    .negatif & {
      color: $c-blue-dark;
    }
  }

  @include mq($until: l) {
    display: none;
  }

  @include mq(m) {
    font-size: 1rem;
  }

  @include mq(l) {
    font-size: 1.1rem;
  }
}

.hero__slides__item__icon {
  display: flex;
  align-items: center;
  margin-top: $spacing;
  margin-bottom: $spacing;

  .icon {
    width: auto;
    max-width: 20rem;
    max-height: 3rem;
    margin-right: $spacing * 0.5;
    fill: $white;

    &.big {
      max-width: none;
      height: 5rem;
      max-height: none;
    }

    &.medium {
      max-width: none;
      height: 4rem;
      max-height: none;
    }

    .picture {
      @include mq(l) {
        height: 100%;
      }
    }

    [class*='tv-extra'] & {
      height: auto;
      max-height: 5rem;
    }
  }

  @include mq(m) {
    .icon {
      height: 5rem;

      &.big {
        height: 8rem;
      }
    }
  }

  @include mq(xl) {
    .icon.big {
      height: 12rem;
    }

    .icon.medium {
      height: 7rem;
    }
  }
}

.hero__navigation__item__progress {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.4rem;
  margin-top: 3rem;
  background-image: linear-gradient(
    to left,
    $c-light 50%,
    $gray-light 50%,
    $gray-light 100%
  );
  background-size: 200%;
  border-radius: 0.2rem;

  .hero__navigation__item.is-active & {
    opacity: 1;
  }

  .hero__navigation__item.is-highlight & {
    @include mq($until: 'l') {
      background-image: linear-gradient(
        to left,
        $c-light 50%,
        $c-light 50%,
        $c-light 100%
      );
    }
  }

  @include mq(l) {
    height: 0.2rem;
    margin-top: 0;

    .negatif & {
      background-image: linear-gradient(
        to left,
        $c-blue-dark 50%,
        $gray-light 50%,
        $gray-light 100%
      );
    }
  }
}

// Modifiers
[class*='hero__slides__item--'][class*='--zuny'] {
  .hero__slides__item__content {
    text-align: center;
  }

  .hero__slides__item__title {
    @extend %fw-black;

    color: $white;
    font-family: $ff-zuny;
    font-size: 2.8rem;

    small {
      font-size: 1.5rem;
      text-transform: uppercase;
    }

    img {
      display: none;
    }
  }

  .hero__slides__item__button {
    background-color: $black;
  }

  .btn__link__title {
    color: $white;
  }

  .btn__icon {
    display: none;
  }

  &:hover {
    .hero__slides__item__button {
      background-color: $white;
    }

    .btn__link__title {
      color: $black;
    }
  }

  @include mq(l) {
    .hero__slides__item__content {
      width: 100%;
      text-align: center;
    }

    .hero__slides__item__title {
      color: $black;
      font-size: 4.8rem;

      img {
        display: inline-block;
        width: 80%;
        max-width: 40rem;
        margin-top: 5rem;
      }
    }
  }
}

[class*='hero__slides__item--'][class*='--tv'] {
  .hero__slides__item__content {
    @include mq($until: l) {
      background-color: $c-blue-dark;

      .hero__slides__item__title-outer {
        color: $white;
      }
    }
  }
}

[class*='hero__slides__item__picture-outer'][class*='--tv'],
[class*='hero__slides__item__picture-outer'][class*='--fixe'],
[class*='hero__slides__item__picture-outer'][class*='--mobile'] {
  .hero__slides__item__picture-inner {
    &::after {
      @include get-all-space;

      content: '';
      z-index: 1;
      display: block;
      background-image: linear-gradient(
        1deg,
        rgba($black, 0) 60%,
        rgba($black, 0.7) 100%,
        $black 140%
      );
    }
  }

  .hero__slides__item__triangles-outer {
    @include get-all-space;

    z-index: 1;
  }

  .hero__slides__item__triangles {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    max-height: 53rem;
  }
}

[class*='hero__slides__item__picture-outer'][class*='--zuny'] {
  background: linear-gradient(to top, $c-zuny-light, $c-zuny-lighter);
}

[class*='hero__slides__item__picture-outer'][class*='--product'] {
  .hero__slides__item__picture-inner {
    &::after {
      @include get-all-space;

      content: '';
      display: block;
      background-color: $c-pink-medium;
      transform: rotate(-57deg) translateY(80%) translateX(20%);

      @include mq(l) {
        transform: rotate(-57deg) translateY(100%);
      }
    }
  }
}

[class*='hero__slides__item__picture-outer--'][class*='--video'] {
  @include mq(l) {
    .hero__slides__item__picture-inner {
      &::after {
        @include get-all-space;

        content: '';
        z-index: 99;
        display: block;
        background: linear-gradient(
          to right,
          rgba($black, 0.6) 50%,
          transparent 100%
        );
      }

      &::before {
        @include get-all-space;

        content: '';
        z-index: 99;
        display: block;
        background-image: linear-gradient(
          1deg,
          rgba($black, 0) 60%,
          rgba($black, 0.7) 100%,
          $black 140%
        );
      }
    }
  }
}

[class*='hero__slides__item__type--'][class*='--promo'] {
  @extend %fw-bold;

  margin-bottom: 0;
  padding: $spacing / 4 $spacing / 2;
  color: $white;
  font-family: $ff-default;
  letter-spacing: 1px;
  background-color: $c-orange;
  border-radius: 4px;
}

[class*='--small-title'],
[class*='--countdown'] {
  @include mq(l) {
    .hero__slides__item__title {
      &:not(.entertain-yourself__header &, .gigateam &, .giga-fiber &) {
        font-size: 5rem !important;
      }
    }
  }
}

[class*='promo--countdown'] {
  @include mq(l) {
    .hero__slides__item__title {
      font-size: 7rem !important;
    }

    .hero__slides__item__excerpt {
      font-size: 1.8em;
    }
  }
}

[class*='--product'] {
  @include mq(l) {
    .hero__slides__item__title {
      font-size: 6.2rem;

      strong {
        font-size: 6.2rem;
        font-weight: 700;
      }
    }
  }
}

.hero__slides__item__popup {
  @extend %fw-bold;

  display: inline-flex;
  margin-top: $spacing * 1.5;
  color: $white;
  font-family: $ff-alt;
  text-decoration: underline;
  cursor: pointe r;
}

.hero__slides__item__trailerlink {
  @extend %fw-bold;

  position: relative;
  display: inline-flex;
  margin-top: $spacing * 1.5;
  color: $white;
  font-family: $ff-alt;
  text-decoration: none;
  background-image: linear-gradient($white, $white);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  cursor: pointer;
  transition: background-size 0.3s $ease-out-quad;

  &:hover,
  &:focus {
    background-position: 100% 100%;
    background-size: 0% 1px;
  }
}

.hero__slides__item__slider-outer {
  display: flex;
  align-items: flex-end;
  height: 100%;
  opacity: 0.4;
}

.hero__slides__item__slider {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 100%;
  max-height: 62rem;
}

.hero__slides_item__slider-inner {
  position: relative;
  display: flex;
  width: fit-content;
  height: 100%;
  margin: auto;
  transform: translate3d(0, 0, 0);
  animation: marquee 90s linear infinite;
  animation-play-state: running;

  > div {
    display: flex;
  }
}

.slide__item {
  display: flex;
  flex-direction: column;
  width: 51.4rem;
  margin-right: 3rem;
  box-shadow: 0 0 127.869px rgba(9, 16, 29, 50%);

  .slide__item__picture {
    &:first-child {
      margin-bottom: 3rem;

      @include mq($until: l) {
        margin-bottom: 1.5rem;
      }
    }
  }

  &.is-highlight {
    width: 110rem;

    .slide__item__picture {
      height: 100%;

      &:first-child {
        margin-bottom: 0;
      }
    }

    @include mq($until: l) {
      width: 68.75vw;
    }
  }

  @include mq($until: l) {
    width: 32.12vw;
    margin-right: 1.5rem;
  }
}

.slide__item__picture {
  display: block;
  width: 100%;
  height: 50%;
  background-color: $c-bunting;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
